import React from "react";

const DrivosityCertifiedData = [
    {
        id: 8,
        title: `DrivosityCERTIFIED`,
        content: 'Recognizing those who keep our communities safe',
        location: `/drivosity-certified`,
        data: null,
    },
    {
        id: 8,
        title: `DRIVOSITY CERTIFIED`,
        content: (
            <>
                <div className='mb-7'>
                    Drivosity Certified reflects our commitment to supporting drivers, promoting community safety, and responsible driving. We’re proud to contribute to our neighborhoods' growing number of safe delivery drivers nationwide.
                </div>
                <div className='mb-7'>
                    This program recognizes the hardworking drivers, managers, and businesses that prioritize safe driving practices. Through our certification program, Certified Safe Drivers receive exclusive perks.
                </div>
                <div className='mb-7'>
                    We are extremely grateful to our Certified Safe Drivers for their commitment to keeping our roads safe.
                </div>
            </>
        ),
        location: `/drivosity-certified`,
        data: null,
    },
];

export {DrivosityCertifiedData};
